import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {CustomPopup} from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { MenuTitle } from "../../component/menu/menuTitle";
import Profile from "./profile/Index";
import Employee from "./employee/Index";
import useGet from "../../api/useGet";

const Account = (props) => {
    const [searchParams] = useSearchParams();

    const [tab, setTab] =  useState(searchParams.get('tab') ? searchParams.get('tab') : 'account');
    const [customPopupData, setCustomPopupData] =  useState(null);

    const accountApi = useGet({
        url:`/account`,
        loginType:"login"
    });
    const accountApiData = accountApi?.data?.user_info;

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="Settings"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <MenuTitle title="Settings" tab={tab} buttons={accountApiData?.type === 1 ? [{name: "계정 관리", tab: "account"}, {name: "직원 관리", tab: "employee"}] : [{name: "계정 관리", tab: "account"}]} func={(e) => {setTab(e)}}/>
                        {tab === 'account' && <Profile/>}
                        {accountApiData?.type === 1 && tab === 'employee' && <Employee/>}
                    </div>
                </div>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
        </>
    );
};

export default Account;